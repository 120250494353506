<template>
  <div class="language-switch">
    <template v-for="item in allShopZones">
      <component :is="item.url ? 'a' : 'span'" class="language-switch__flag" :href="item?.url ? `https://${getDomain(item)}${cleanUrl(item.url)}` : undefined" :hreflang="item.locale" :class="{ 'cursor-pointer': item.shopZoneId !== currentShopZone.shopZoneId, 'language-switch__flag--current': item.shopZoneId === currentShopZone.shopZoneId && !hovering }" @mouseenter="hovering = true" @mouseleave="hovering = false" @click="trySwitch(item)">
        <img class="language-switch__flag__image" :src="`/img/flags/${item.locale}.svg`" :alt="`${item.locale} flag`" />
        <div class="language-switch__flag__label">{{ item.language }}</div>
      </component>
    </template>
  </div>
</template>
<script setup>

const appConfig = useAppConfig();
const currentShopZone = useShopZone().value;
const route = useRoute();

const allowedShopZones = currentShopZone.relatedShopZoneIds;

const hovering = ref(false);

// Show current shopZone first
let allShopZones = [
  currentShopZone,
  ...allowedShopZones.map(id => {
    const szObj = appConfig.shopZones.find(shopZone => shopZone.shopZoneId === id);
    szObj.url = usePage().value.page?.alternativeUrls?.find(z => z.shopZone.id === id)?.url;

    if (Object.entries(route.query).length) {
      szObj.url += '?' + Object.entries(route.query)
        .map(([key, value]) => `${encodeURIComponent(key)}=${encodeURIComponent(value)}`)
        .join('&');
    }

    if (route.hash) {
      szObj.url += route.hash;
    }

    return szObj;
  })
];

const getDomain = (item) => {
  if (useState('isDev').value) {
    return item.devDomain;
  }
  else {
    return item.domain;
  }
}

const cleanUrl = (dirtyUrl) => {
  if (dirtyUrl && dirtyUrl.endsWith('/home')) {
    return dirtyUrl.substring(0, dirtyUrl.length - 5);
  }

  return dirtyUrl;
}

const trySwitch = (item) => {
  if (!item.url && item.shopZoneId !== currentShopZone.shopZoneId) {
    useDialog().open('noAlternativeUrl', { homePageUrl: `https://${getDomain(item)}` });
  }
}

useHead({
  link: allShopZones.filter(altShopZone => altShopZone.url).map(altShopZone => {
    return {
      rel: 'alternate',
      href: `https://${getDomain(altShopZone)}${cleanUrl(altShopZone.url)}`,
      hreflang: altShopZone.locale
    };
  })
});

</script>
<style lang="postcss">
.language-switch {

  @apply hidden desktop:flex;

  &__flag {

    @apply inline-flex items-center gap-2 pl-2 pr-2;

    &__image {
      @apply w-6 rounded-[4px] opacity-30 saturate-50 transition-all;
    }

    &__label {
      @apply font-bold text-sm uppercase text-neutral-300 transition-all;
    }

    &--current {
      @apply cursor-default;
    }

    &:hover,
    &--current {

      @apply !no-underline;

      .language-switch__flag__label {
        @apply text-neutral-50;
      }

      .language-switch__flag__image {
        @apply opacity-100 saturate-100;
      }
    }
  }
}
</style>