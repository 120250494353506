<template>
  <header class="header" data-pagearea="header">
    <div :style="isSticky ? `padding-bottom: ${(isMobile ? stickyMobileAnchor.clientHeight - stickyDesktopAnchor.clientHeight : stickyDesktopAnchor.clientHeight)}px` : ''">
      <div ref="stickyMobileAnchor" class="top-0" :class="{ 'fixed w-full header--sticky': isMobile && isSticky, 'header--potential-sticky': isMobile, 'top-0 bottom-0 flex flex-col items-between': isMobile && isMenuOpen }">
        <div class="relative z-[2002] bg-neutral-500 text-neutral-50">
          <div class="page-container relative">
            <MoleculesGlobalTextMenu class="hidden tablet:block" />
            <div class="flex justify-between items-center gap-4 tablet:gap-8 py-2 tablet:py-4 text-xs tablet:text-base">
              <MoleculesGlobalLogo class="tablet:mr-auto" @click="isMenuOpen = false" />
              <MoleculesGlobalSearchBox :class="['tablet:absolute tablet:left-[180px] tablet:w-[calc(100%-550px)] desktop:left-[240px]', { 'desktop:w-[calc(100%-1140px)]': withCurrencySwitch, 'desktop:w-[calc(100%-1000px)]': !withCurrencySwitch }]" />
              <MoleculesGlobalLanguageSwitch />
              <MoleculesGlobalCurrencySwitch v-if="withCurrencySwitch" class="hidden desktop:block" />
              <MoleculesGlobalCustomerControls :is-logged="useCustomer().isLogged()" />
              <MoleculesGlobalCartIcon :total-price="cart.prices.value.itemsTotal.value - cart.prices.value.itemsCouponSale.value" :total-quantity="cart.totalQuantity.value" :cart-items="cart.presentation.value" />
              <MoleculesGlobalMenuIcon v-model="isMenuOpen" />
            </div>
          </div>
        </div>
        <div ref="stickyDesktopAnchor" id="stickyDesktopAnchor" :class="{ 'fixed top-0 w-full header--sticky': isDesktop && isSticky, 'header--potential-sticky': isDesktop, 'flex-1': isMobile && isMenuOpen, 'overflow-y-auto': isMobile && isSticky }">
          <MoleculesGlobalPrimaryNav v-model="isMenuOpen" :groups="groups" :key="useRoute().hash" />
          <LazyOrganismsDialogsMessage :show="message.show && !isFixedHeaderDisabledForThisModule" :icon="message.icon" :text="message.text" :button-text="message.buttonText" :type="message.type" @hide="hideMessage" class="z-[-1]" />
        </div>
      </div>
    </div>
    <div class="desktop:hidden bg-neutral-500 text-neutral-50 overflow-x-auto border-t border-darkmode-50">
      <div class="page-container">
        <div class="flex justify-between">
          <AtomsTextLink v-for="item in groups" class="header__mobile-group" :href="item.page.url">{{ item.name }}</AtomsTextLink>
        </div>
      </div>
    </div>
  </header>
  <LazyAtomsGlobalOverlay :isVisible="isOverlayVisible" />
</template>

<script setup>

import { useBreakpoints, useElementBounding } from '@vueuse/core';

const appConfig = useAppConfig();
const cart = useCart();
const view = useBreakpoints(appConfig.breakpoints);
const locale = useLocale().getLocale();
const shopZone = useShopZone().value;

const hideMessage = () => useMessage().hide();

const message = useMessage().message;

const stickyMobileAnchor = ref(null);
const stickyDesktopAnchor = ref(null);
const isDesktop = ref(false);
const isMobile = ref(false);
const isSticky = ref(false);

const isMenuOpen = ref(false);

const isOverlayVisible = computed(() => isMenuOpen.value);

const withCurrencySwitch = shopZone.allowedFeatures.includes('CURRENCY_SWITCH');

const stickyBoundary = ref(0);

const recomputeStickyBoundary = () => {
  if (!isSticky.value) {
    if (view.greaterOrEqual('desktop').value && stickyDesktopAnchor.value) {
      isDesktop.value = true;
      isMobile.value = false;

      stickyBoundary.value = stickyDesktopAnchor.value.getBoundingClientRect().top + window.scrollY;
    }
    else if (stickyMobileAnchor.value) {
      isMobile.value = true;
      isDesktop.value = false;

      stickyBoundary.value = stickyMobileAnchor.value.getBoundingClientRect().top + window.scrollY;
    }
  }
}

onMounted(() => {
  if (process.client) {
    window.addEventListener('scroll', handleScroll);
    window.addEventListener('resize', handleScroll(recomputeStickyBoundary()));

    if (view.greaterOrEqual('desktop').value) {
      isDesktop.value = true;
      isMobile.value = false;
    }
    else {
      isMobile.value = true;
      isDesktop.value = false;
    }

    recomputeStickyBoundary();
  }
});

onUnmounted(() => {
  if (process.client) {
    window.removeEventListener('scroll', handleScroll);
    window.removeEventListener('resize', handleScroll);
  }
});

watch(() => useRoute().hash, () => {
  if (isMenuOpen.value) {
    isMenuOpen.value = false;
  }

  groups.value.filter(q => q.isSubMenuOpen).forEach(item => {
      item.isSubMenuOpen = false;
    });
});

watch(() => isMenuOpen.value, () => {

  if (!isMenuOpen.value) {
    groups.value.filter(q => q.isSubMenuOpen).forEach(item => {
      item.isSubMenuOpen = false;
    });

    if (!view.greaterOrEqual('desktop').value) {
      useUI().setBodyScrollable(true);
    }
  }
  else {
    // when on mobile, scroll to top of the header (below topBar)
    if (!view.greaterOrEqual('desktop').value && !isSticky.value) {
      useUI().scrollToElement(stickyMobileAnchor.value, -1);
    }

    if (!view.greaterOrEqual('desktop').value) {
      useUI().setBodyScrollable(false);
    }
  }

});

const isFixedHeaderDisabledForThisModule = computed(() => appConfig.moduleIdsWithDisabledFixedHeader.includes(usePage().value?.page?.module.id));

const handleScroll = (callback) => {

  // disable sticky header on ware page
  isSticky.value = (!isFixedHeaderDisabledForThisModule.value || isMenuOpen.value) && window.scrollY >= stickyBoundary.value;

  if (callback && typeof callback === 'function') {
    callback();
  }
}

const groups = useState('groups', () => null);

</script>
<style lang="postcss">
.header {

  @apply relative z-[31] bg-neutral-500 text-neutral-50;

  &__link {
    @apply inline-block my-2 py-2 mx-3 uppercase text-neutral-300 hover:text-neutral-50 transition-colors;
  }

  &__mobile-group {
    @apply inline-block px-2 py-3 whitespace-nowrap;

    &:first-of-type {
      @apply pl-0;
    }

    &:last-of-type {
      @apply pr-4;
    }
  }

  &--sticky {

    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    /*
    /* breaks search box on mobile
    -webkit-transform: translate3d(0, 0, 0);
    will-change: transform;
    */
  }
}
</style>