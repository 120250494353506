<template>
  <button class="relative text-center ml-2 cursor-pointer tablet:ml-0 tablet:mr-4 desktop:hidden" @click="toggle">
    <AtomsImagesIcon icon="menu" size="lg tablet:text-2xl" />
    <div class="-mt-1">{{ $t('Menu') }}</div>
    <Transition>
      <div v-show="props.modelValue" class="absolute left-0 top-0 right-0 bottom-0 flex items-center justify-center bg-neutral-500">
        <AtomsImagesIcon icon="cross" size="lg" />
      </div>
    </Transition>
  </button>
</template>
<script setup>

const props = defineProps({
  modelValue: Boolean
});

const emit = defineEmits(['update:modelValue']);

const toggle = () => emit('update:modelValue', !props.modelValue);

</script>