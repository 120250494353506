<template>
  <div class="currency-switch">
    <span class="currency-switch__label">{{ $t('Currency') }}</span> <strong>{{ currentCurrencySign }} {{ currentCurrency }}</strong>
    <AtomsImagesIcon icon="arrow-right-carousel" class="currency-switch__icon" />
    <div class="currency-switch__menu">
      <label v-for="currency in shopZone.allCurrencies" class="currency-switch__menu__item">
        <AtomsFormsRadio :value="currency" v-model="currentCurrency" />
        <span>{{ appConfig.currencies[currency].signLocal }}</span>
        {{ currency }}
      </label>
    </div>
  </div>
</template>
<script setup>

const appConfig = useAppConfig();
const shopZone = useShopZone().value;
const locale = useLocale();

const currentCurrency = ref(locale.getLocale().currency.sign);

const currentCurrencySign = computed(() => {
  return appConfig.currencies[currentCurrency.value].signLocal;
});

watch(() => currentCurrency.value, (newValue) => {
  locale.setCurrency(newValue);
});

</script>
<style lang="postcss">
.currency-switch {

  @apply relative z-[1] px-3 py-2 text-lg tracking-normal cursor-pointer transition-all;

  &__label {
    @apply mr-2 text-neutral-300;
  }

  &__icon {
    @apply inline-block ml-2 -mt-1 text-xs rotate-90 transition-all;
  }

  &__menu {
    @apply absolute top-[0] left-0 right-0 z-[-1] max-h-0 bg-neutral-50 overflow-hidden;

    &__item {

      @apply flex items-center gap-3 px-3 py-1;

      &:first-of-type {
        @apply mt-11 pt-4 border-t border-neutral-200;
      }

      &:last-of-type {
        @apply pb-4;
      }
    }
  }

  &:hover {
    @apply text-neutral-500;
  }

  &:hover &__label {
    @apply text-neutral-400;
  }

  &:hover &__icon {
    @apply -rotate-90;
  }

  &:hover &__menu {
    animation: slide-toggle 0.6s;
    animation-fill-mode: forwards;
    animation-delay: 0.2s;
  }
}
</style>