<template>
    <div id="topBar" v-if="show" class="top-bar" :style="`color: ${props.textColor}; background-color: ${props.backgroundColor};`" ref="topBar" data-pagearea="top_bar">
        <div class="page-container flex gap-2 items-center p-3 relative">
            <div class="flex-1" v-html="htmlContentWithAnnotatedLinksForAnalytics"></div>
            <button @click="close">
                <AtomsImagesIcon icon="cross" :title="$t('Zavřít')" />
            </button>
        </div>
    </div>
</template>
<script setup>

import { useElementSize } from '@vueuse/core';

const props = defineProps({
    isEnabled: Boolean,
    textColor: String,
    backgroundColor: String,
    html: String,
    campaign: String
});

const topBar = ref(null);
const justClosed = ref(false);

const { height } = useElementSize(topBar);

defineExpose({ height });

const cookieName = 'topBarClosed';

let show = computed(() => props.isEnabled && useShopCookie().read(cookieName) == null && !justClosed.value);

const htmlContentWithAnnotatedLinksForAnalytics = computed(() => props.html?.replaceAll('<a', `<a data-promotioncampaign="${props.campaign?.replaceAll('"', '')}" data-promotiontype="text" data-promotionname="${props.html?.substring(0, 100).replaceAll('"', '')}"`));

const close = () => {
    useShopCookie().set(cookieName, true, 24);
    document.getElementById('topBar').style.maxHeight = 0;
    setTimeout(() => justClosed.value = true, 250);

    useBaseAnalytics().pushEvent('user_interaction', {
        interaction_name: 'top_bar_close'
    });
}

const setMaxHeight = () => {
    const element = document.getElementById('topBar');
    if (element) {
        element.style.maxHeight = null;
        const height = element.clientHeight;
        element.style.maxHeight = height + 'px';
    }
}

onMounted(() => {
    window.addEventListener('resize', () => { setMaxHeight(); });
    setMaxHeight();
});

</script>
<style lang="postcss">
.top-bar {

    @apply relative z-40 text-center overflow-hidden transition-all;

    a {
        @apply underline;
    }
}
</style>