<template>
  <div class="customer-controls">
    <AtomsTextLink :to="props.isLogged ? cache.getModuleUrl('modules/account/myAccount') : cache.getModuleUrl('modules/login')"><AtomsImagesIcon icon="user" size="lg tablet:text-2xl" /></AtomsTextLink>
    <div :class="{ 'hidden desktop:block' : !props.showFull }">
      <template v-if="props.isLogged">
        <AtomsTextLink :to="cache.getModuleUrl('modules/account/myAccount')" class="ml-6">{{ $t('Můj účet') }}</AtomsTextLink>
      </template>
      <template v-else>
        <AtomsTextLink :to="cache.getModuleUrl('modules/login')" class="ml-6">{{ $t('Přihlášení') }}</AtomsTextLink>
        <span class="border-r-2 border-neutral-50 mx-4"></span>
        <AtomsTextLink :to="cache.getModuleUrl('modules/register')">{{ $t('Registrace') }}</AtomsTextLink>
      </template>
    </div>
  </div>
</template>
<script setup>

const cache = useCache();

const props = defineProps({
  isLogged: Boolean,
  showFull: Boolean
});

</script>
<style lang="postcss">
.customer-controls {
  @apply flex items-center;

  & a:hover {
    @apply text-secondary-500 no-underline;
  }
}
</style>
