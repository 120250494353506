<template>
    <div class="primary-nav" data-pagearea="main_menu">
        <div class="page-container">
            <div class="primary-nav__menu" :class="{ 'is-open': props.modelValue }" @mouseover="view.greaterOrEqual('desktop').value && emit('update:modelValue', true)" @mouseleave="view.greaterOrEqual('desktop').value && emit('update:modelValue', false);">
                <div v-if="props.modelValue" class="desktop:hidden">
                    <MoleculesGlobalCustomerControls :show-full="true" />
                </div>
                <MoleculesBlocksPrimaryNavLevel :items="props.groups" :level="1" />
                <div v-if="props.modelValue" class="desktop:hidden">

                    <!-- text menu on mobile -->

                    <ul class="primary-nav__text-menu">
                        <li v-for="item in textMenu">
                            <AtomsTextLink :to="item.href" class="primary-nav__link">
                                <div class="primary-nav__link__wrapper">
                                    <span class="flex-1">{{ item.text }}</span>
                                    <AtomsImagesIcon icon="arrow-right-carousel" size="xs" class="primary-nav__link__dropdown" />
                                </div>
                            </AtomsTextLink>
                        </li>
                        <li>
                            <AtomsTextLink :to="cache.getPageUrl(28750)" class="primary-nav__link">
                                <div class="primary-nav__link__wrapper">
                                    <span class="flex-1">{{ $t('Značky') }}</span>
                                    <AtomsImagesIcon icon="arrow-right-carousel" size="xs" class="primary-nav__link__dropdown" />
                                </div>
                            </AtomsTextLink>
                        </li>
                        <li v-if="!shopZone.disabledFeatures.includes('SERVICES_LINK_IN_HEADER')">
                            <AtomsTextLink :to="cache.getPageUrl(33210)" class="primary-nav__link">
                                <div class="primary-nav__link__wrapper">
                                    <span class="flex-1">{{ $t('Služby') }}</span>
                                    <AtomsImagesIcon icon="arrow-right-carousel" size="xs" class="primary-nav__link__dropdown" />
                                    <sup class="absolute left-8 top-2 text-xs uppercase text-secondary-500">{{ $t('Novinka') }}</sup>
                                </div>
                            </AtomsTextLink>
                        </li>
                    </ul>

                    <div class="flex flex-wrap items-center justify-center gap-x-8 py-4">

                        <!-- language switch on mobile -->

                        <MoleculesGlobalLanguageSwitch class="gap-4 px-6 py-3" />

                        <!-- currency switch on mobile -->

                        <div v-if="withCurrencySwitch" class="primary-nav__currency">
                            <label v-for="currency in shopZone.allCurrencies" class="primary-nav__currency__item">
                                <AtomsFormsRadio :value="currency" v-model="currentCurrency" />
                                <span>{{ appConfig.currencies[currency].signLocal }}</span>
                                {{ currency }}
                            </label>
                        </div>
                    </div>
                </div>
            </div>

            <!-- 1st level on desktop "značky" -->

            <li class="primary-nav__item primary-nav__item--1 hidden !flex-none desktop:block">
                <AtomsTextLink :to="cache.getPageUrl(28750)" class="primary-nav__link">
                    <div class="primary-nav__link__wrapper pr-8">
                        {{ $t('Značky') }}
                    </div>
                </AtomsTextLink>
            </li>

            <!-- 1st level on desktop "služby" -->

            <li v-if="!shopZone.disabledFeatures.includes('SERVICES_LINK_IN_HEADER')" class="primary-nav__item primary-nav__item--1 hidden !flex-none desktop:block">
                <AtomsTextLink :to="cache.getPageUrl(33210)" class="primary-nav__link">
                    <div class="primary-nav__link__wrapper pr-4">
                        {{ $t('Služby') }}
                        <sup class="absolute left-[30%] -top-3 text-xs uppercase text-secondary-500">{{ $t('Novinka') }}</sup>
                    </div>
                </AtomsTextLink>
            </li>
        </div>
    </div>
</template>
<script setup>

import { useBreakpoints } from '@vueuse/core';

const props = defineProps({
    modelValue: Boolean,
    groups: Array
});

const appConfig = useAppConfig();
const shopZone = useShopZone().value;
const cache = useCache();
const i18n = useI18n();
const locale = useLocale();
const view = useBreakpoints(appConfig.breakpoints);

const emit = defineEmits(['update:modelValue']);

const withCurrencySwitch = shopZone.allowedFeatures.includes('CURRENCY_SWITCH');

const textMenu = [
    {
        href: cache.getModuleUrl('modules/branches'),
        text: i18n.t('Prodejny')
    },
    {
        href: cache.getPageUrl(28805),
        text: i18n.t('Stav objednávky')
    },
    {
        href: cache.getPageUrl(3449),
        text: i18n.t('Slevy a výhody')
    },
    {
        href: cache.getPageUrl(26558),
        text: i18n.t('Informační centrum')
    },
    {
        href: cache.getModuleUrl('modules/inspiration'),
        text: i18n.t('Inspirace')
    },
    {
        href: cache.getPageUrl(18438),
        text: i18n.t('Magazín')
    },
    {
        href: cache.getPageUrl(3453),
        text: i18n.t('Kontakty')
    }
];

const currentCurrency = ref(locale.getLocale().currency.sign);

watch(() => currentCurrency.value, (newValue) => {
    locale.setCurrency(newValue);
});

</script>

<style lang="postcss">
.primary-nav {
    z-index: 2001;
    @apply relative border-darkmode-50 desktop:border-t desktop:bg-neutral-500;

    &>.page-container {
        @apply relative flex;
    }

    &__items--1 {
        list-style-type: none;
        margin: 0;

        @screen desktop {
            @apply flex;
            height: auto !important;
        }

        @screen maxtablet {
            @apply text-neutral-500;
        }
    }

    &__items--2 {
        /* mobile-first */
        position: initial;
        border: 0;
        padding-top: 0;
        @apply text-neutral-500;

        /* applies only on desktop screens so it doesn´t affect mobile inheritance */
        @screen desktop {
            @apply grid bg-neutral-50 gap-3 px-6 left-4 right-4;
            position: absolute;
            top: 100%;
            z-index: 1000;
            overflow: hidden;
            opacity: 0;
            max-height: 0;
            min-height: 0;
            transition: min-height 0.3s ease-in-out, max-height 0.6s ease-in-out;
            transition-delay: 0.15s;
            pointer-events: none;
            grid-template-columns: repeat(4, 1fr);
        }

        @screen maxtablet {
            max-height: 0;
            overflow: hidden;
            opacity: 0;
            @apply bg-neutral-200 px-8;
        }
    }

    &__menu {

        @apply flex-1 pointer-events-auto;

        /* default mobile-first "explorer" list */
        @screen maxtablet {
            position: absolute;
            top: 100%;
            width: 100%;
            padding: 0;
            max-height: 0;
            overflow: hidden;
            opacity: 0;
            transition: all 0.25s ease;
            @apply bg-neutral-50;
        }

        @screen desktop {
            top: 100% !important;
            padding-left: 0 !important;
            padding-right: 0 !important;

            &:hover {
                .primary-nav__items--2 {
                    min-height: 240px;
                    max-height: 100vh;
                }
            }
        }
    }

    &__item--1 {

        @screen desktop {

            display: flex;
            flex: 1;
            align-items: center;

            &>.primary-nav__link {

                @apply px-4 py-6;
                flex: 1;
                flex-direction: column;
                align-items: center;
                justify-content: space-between;
                text-decoration: none;
                display: flex;
                text-align: center;
                transition: all 0.25s ease;

                & .icon {
                    margin-right: 0.8em;
                }

                & .primary-nav__link__dropdown {
                    @apply rotate-90;
                    transition-delay: .2s;
                }
            }

            &:hover {

                &>.primary-nav__link {
                    @apply text-primary-400;
                }

                & .primary-nav__link__dropdown {
                    @apply -rotate-90;
                }

                /* open the pane on the desktop screens */
                &>.primary-nav__items {
                    opacity: 1;
                    pointer-events: all;
                }
            }
        }

        @screen maxtablet {
            &>.primary-nav__link {

                @apply max-w-full overflow-hidden px-4 border-t border-neutral-50;

                &:hover {
                    @apply no-underline !font-normal;
                }

                &>div {
                    @apply flex-1 justify-between;
                }

                .icon {
                    @apply rotate-90;
                }
            }
        }
    }

    &__item--2 {
        @screen desktop {
            @apply flex flex-1 min-h-[3.5rem] border-t border-neutral-200 text-lg;

            &>.primary-nav__link {
                @apply flex flex-1 items-center pt-3 hover:no-underline;
            }

            &:nth-of-type(2),
            &:nth-of-type(3),
            &:nth-of-type(4),
            &:nth-of-type(5) {
                @apply border-0 mt-6;
            }
        }

        @screen maxtablet {
            &>.primary-nav__link {
                @apply flex flex-1 min-h-[4rem] py-2 hover:no-underline;
            }
        }
    }

    &__item--3 {
        @screen desktop {
            &>.primary-nav__link {
                font-size: 0.9rem;

                &:hover {
                    text-decoration: underline;
                }
            }
        }
    }

    &__item {

        @screen desktop {
            @apply flex;
        }

        @screen maxtablet {

            &:first-of-type {
                border-top: 0;
            }

            &>.primary-nav__link {
                display: flex;
                flex: 1;
                align-items: center;
                text-align: left;
                text-transform: none;
                font-size: 1em;
            }
        }
    }


    &__wrapper {
        display: flex;

        &.page-container {
            padding: 0;
        }
    }

    &__menu {

        &>.primary-nav__link {
            display: none;

            @screen maxtablet {
                display: flex;
                cursor: pointer;
                text-decoration: none;
                font-weight: bold;
                text-transform: uppercase;
                padding: 0.5em 1.5em;
                padding-top: 0;
                text-align: left;
                align-items: center;
                transition: all 0.25s ease;

                & .icon {
                    margin-right: 0.8em;
                }
            }
        }
    }

    &__extra-items {
        @apply flex flex-wrap mt-4 -mx-2 bg-neutral-400 text-neutral-50 desktop:mt-6 desktop:mx-0 desktop:text-lg;

        li {
            @apply relative w-full desktop:flex-1;

            &:last-of-type {
                a::after {
                    @apply hidden;
                }

                @screen maxtablet {
                    @apply border-b-0;
                }
            }

            @screen maxtablet {
                @apply mx-4 border-b border-neutral-50;
            }
        }

        a {
            @apply flex items-center gap-2 pl-8 pr-4 py-4 desktop:gap-6 desktop:justify-center desktop:pl-0 desktop:pr-0 hover:no-underline;

            &::after {
                @apply content-[''] absolute hidden right-0 h-6 border-r border-neutral-100 desktop:block;
            }

            @screen maxtablet {
                @apply pl-4;
            }
        }

        .icon {
            @apply w-[60px] text-center text-xl desktop:text-3xl;
        }
    }

    &__text-menu {
        @apply relative -top-[1px] bg-neutral-500;

        .primary-nav__link {
            @apply inline-block w-full px-4 hover:no-underline;

            &__wrapper {
                @apply pl-4 border-neutral-300;
            }
        }

        li:last-of-type .primary-nav__link__wrapper {
            @apply border-0;
        }
    }

    &__link {

        @apply transition-colors;

        &__dropdown {
            @apply p-6 transition-transform desktop:p-0;
        }

        &__wrapper {
            @apply relative flex items-center gap-2;

            @screen maxtablet {
                @apply border-b border-neutral-200;
            }
        }
    }

    .customer-controls {
        @apply justify-center py-4 bg-neutral-400;

        a:hover {
            @apply !text-neutral-50;
        }
    }

    .language-switch {
        @apply flex justify-center;

        .language-switch__flag--current .language-switch__flag__label,
        .language-switch__flag:hover .language-switch__flag__label {
            @apply text-neutral-400;
        }
    }

    &__currency {

        @apply flex gap-6 p-6 py-3;

        &__item {
            @apply inline-flex items-center gap-2 text-neutral-500;
        }
    }
}

.is-open {

    @screen maxtablet {

        &.primary-nav__menu {

            display: block;
            position: absolute;
            left: 0;
            top: 100%;
            z-index: 1000;
            animation: slide-toggle 0.3s;
            animation-fill-mode: forwards;
        }

        &>.primary-nav__items--2 {
            @apply relative -top-[1px] pt-[1px];
            overflow: initial;
            animation: slide-toggle 0.3s;
            animation-fill-mode: forwards;
        }

        &>.primary-nav__link {

            @apply relative -top-[1px] pt-[1px] bg-neutral-400 text-neutral-50;

            .primary-nav__link__dropdown {
                @apply relative -rotate-90;
            }

            .primary-nav__link__wrapper {
                @apply border-neutral-400;
            }
        }
    }
}

.primary-nav--open {
    .page-wrapper {
        display: none;
    }
}
</style>