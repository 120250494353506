<template>
  <div class="border-b border-darkmode-50 text-xs text-right">
    <AtomsTextLink class="header__link" :to="cache.getModuleUrl('modules/branches')">{{ $t('Prodejny') }}</AtomsTextLink> |
    <AtomsTextLink class="header__link" :to="cache.getPageUrl(28805)">{{ $t('Stav objednávky') }}</AtomsTextLink> |
    <AtomsTextLink class="header__link" :to="cache.getPageUrl(3449)">{{ $t('Slevy a výhody') }}</AtomsTextLink> |
    <AtomsTextLink class="header__link" :to="cache.getPageUrl(26558)">{{ $t('Informační centrum') }}</AtomsTextLink> |
    <AtomsTextLink class="header__link" :to="cache.getModuleUrl('modules/inspiration')">{{ $t('Inspirace') }}</AtomsTextLink> |
    <AtomsTextLink class="header__link" :to="cache.getPageUrl(18438)">{{ $t('Magazín') }}</AtomsTextLink> |
    <AtomsTextLink class="header__link" :to="cache.getPageUrl(3453)">{{ $t('Kontakty') }}</AtomsTextLink>
  </div>
</template>
<script setup>

const cache = useCache();

</script>